*{
  box-sizing: border-box;
}

body {
  padding: 0px;
  background-color: lightgrayz;
}

.enquire {
  padding-left: 45%;
}

.enquire h2 {
  cursor: pointer;
}

.upperNav{
  position: absolute;
  z-index: 2;
  width: 100%;
  /* background-color: rgba(0, 0, 0, 0.3); */
}

.clientSection{
  margin: 1vw;
}

h2 {
  /* background-color: rgb(188, 6, 6); */
  background-color: #187bcd;
  color: white;
  padding: 10px 25px;
  width: fit-content;
}

.space {
  height: 2vh;
  width: 100px;
}

/* .space1{
  height: 0.1vh;
} */

.bottomSpace {
  height: 100px;
  width: 100px;
}

@media only screen and (max-width: 768px) {
  .pr{
    width: 85%;
    margin: 0 auto;
  }
  .carousel_landing button{
    height: 2.5rem;
    width: 2.5rem;
  }
  .carousel_landing svg{
    height: 2rem;
    width: 2rem;
  }
}

/* Upper-carousel window */
.carousel_landing {
  position: relative;
  left: 0px;
}

/* Upper-carousel content css from react impoorted package */
article.sc-jTrPJq.dxRBqr {
  width: auto;
  height: fit-content;
  /* height: 60vh; */
  /* width: 98.8vw; */
}

.carousel_landing .heading {
  position: absolute;
  top: 50%;
  left: 45%;
}

.carousel_landing .heading_1 {
  position: absolute;
  top: 50%;
  left: 45%;
}

.carousel_landing .heading_2 {
  position: absolute;
  top: 50%;
  left: 45%;
}

.carousel_landing img{
  padding: 0px;
}
