.counterCoverSafe {
  background-image: url("./Images/bgCounter1.png");
  /* background-attachment: fixed; */
  background-size: cover;
}

.counterCover {
  display: flex;
  flex-direction: row;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 5rem;
  justify-content: space-evenly;
  font-weight: 500;
}

.counterCover img {
  padding: 0;
  margin: 0;
}

.counts {
  display: flex;
  flex-direction: column;
  width: fit-content;
  font-size: 1.8rem;
  justify-content: center;
  align-items: center;
}

.counts p {
  margin: 0.5vh;
  line-height: 3vh;
  color: white;
}

.counts img {
  height: 12vh;
  width: auto;
  margin-bottom: 1vh;
  filter: brightness(0) invert(1);
}

.counts1 {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 1.8rem;
  width: fit-content;
  justify-content: center;
  align-items: center;
}

.counts1 p {
  margin: 0.5vh;
  line-height: 3vh;
  color: white;
}

.counts1 img {
  height: 12vh;
  width: auto;
  margin-bottom: 1vh;
  filter: brightness(0) invert(1);
}

.counts2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 1.8rem;
  width: fit-content;
  justify-content: center;
  align-items: center;
  margin-top: -2vh;
}

.counts2 p {
  margin: 0.5vh;
  line-height: 3vh;
  color: white;
}

.counts2 img {
  height: 14vh;
  width: auto;
  margin-bottom: 1vh;
  filter: brightness(0) invert(1);
}

.counts3 {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 1.8rem;
  width: fit-content;
  justify-content: center;
  align-items: center;
  margin-top: -3vh;
}

.counts3 p {
  margin: 0.5vh;
  line-height: 3vh;
  color: white;
}

.counts3 img {
  height: 15vh;
  width: auto;
  margin-bottom: 1vh;
  filter: brightness(0) invert(1);
}

.flex_container {
  display: flex;
  color: white;
  background-color: darkslategray;
  justify-content: space-around;
  /* background: linear-gradient(-45deg, #000 40%, #fff 0 60%, #000 0) right/300%
      100% no-repeat,
    linear-gradient(-45deg, darkslategray 40%, #000 0 60%, darkslategray 0)
      right/300% 100% no-repeat;
  background-clip: text, padding-box; */
  background:#458cc7;
  transition: 0.8s;
  padding-bottom: 1vh;
  flex-direction: row;
}

.flex_container h3 {
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: 2vh;
}

.flex_container:hover {
  background-position: left;
}

.phone {
  position: relative;
  top: -4vh;
  font-size: 1rem;
}

.phone span {
  font-weight: bold;
}

.email {
  position: relative;
  top: -4vh;
  font-size: 1 rem;
}
.email span {
  margin-left: -3px;
  font-weight: bold;
}

.email p {
  margin-bottom: 1vh;
  font-size: 1.7rem;
}

.ql {
  margin-left: 0px;
  font-size: 1rem;
  /* width: 20vw; */
}

.menuCover {
  display: flex;
}

.cu {
  margin-left: 0px;
  font-size: 1rem;
}

.cu_info {
  font-size: 1rem;
  position: relative;
  transition: all 0.5s;
  text-align: justify;
}

.cu_info p span {
  font-weight: bold;
}

hr {
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 40px;
  margin-bottom: 40px;
}
.map {
  margin-top: 0px;
  font-size: 1rem;
  text-align: center;
}

.map a:hover {
  transform: scale(1);
  background-color: transparent;
}

.map img {
  width: 25vw;
  height: auto;
  position: relative;
  transition: all 1s ease;
  object-fit: fill;
}

.map img:hover {
  transform: scale(none);
}

.ql .menuLinks {
  display: flex;
  flex-direction: column;
  font-size: 1rem;
}

.ql .menuLinks a {
  color: white;
  text-decoration: none;
  line-height: 2.5rem;
}

.ql .menuLinks a:hover {
  /* background-color: rgb(94, 138, 138); */
  color: #187bcd;
  transform: scale(1);
}

.ql .menuLinks1 {
  display: flex;
  flex-direction: column;
  font-size: 1.3rem;
}

.ql .menuLinks1 a {
  color: white;
  text-decoration: none;
  line-height: 2.5rem;
}

.ql .menuLinks1 a:hover {
  /* background-color: rgb(94, 138, 138); */
  color: #187bcd;
  transform: scale(none);
}

.logoCol {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logoCol img {
  position: relative;
  width: 23vw;
  height: auto;
}

.aboutImg {
  display: flex;
  justify-content: space-around;
}

.aboutImg img {
  height: 23vh;
  width: auto;
  padding: 1vw;
}

@media only screen and (max-width: 768px) {
  .counterCover {
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 3rem;
  }

  .counts {
    font-size: 1.3rem;
    position: relative;
  }

  .counts img {
    height: 6vh;
  }

  .counts1 {
    font-size: 1.3rem;
    position: relative;
    margin-top: 2rem;
  }

  .counts1 img {
    height: 6vh;
  }

  .counts2 {
    font-size: 1.3rem;
    margin-top: 2rem;
  }

  .counts2 img {
    height: 7vh;
  }

  .counts3 {
    font-size: 1.3rem;
    margin-top: 2rem;
  }

  .counts3 img {
    height: 8vh;
  }
  .flex_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .flex_container h3 {
    font-size: 1.5rem;
    font-weight: bold;
  }
  .logoCol img {
    width: 65vw;
    height: auto;
    object-fit: contain;
  }
  .aboutImg img {
    height: 14vh;
    width: auto;
    padding: 1vw;
  }
  .cu {
    text-align: center;
  }

  .cu_info {
    text-align: justify;
    margin: 0 auto;
    font-size: 0.9rem;
  }

  .cu_info p span {
    font-weight: bold;
  }
  .map img {
    width: 80vw;
    height: auto;
  }
  .phone {
    font-size: 1rem;
  }
  .email {
    font-size: 1.1rem;
  }
  .ql .menuLinks {
    font-size: 1rem;
    font-weight: 600;
  }
}
