.topic_s {
  cursor: pointer;
}

.topic_ms {
  cursor: pointer;
}

.topic_p {
  /* padding-left: 42%; */
  cursor: pointer;
}

.prodSection {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.prodSection .indication{
  display: none;
}

.sectorSection {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sectorSection .indication{
  display: none;
}

.manufacturingSection {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.manufacturingSection .indication{
  display: none;
}

.container_s {
  display: flex;
  width: 95vw;
  margin: 0 auto;
  flex-direction: column;
  justify-content: space-around;
  padding: 0px;
  border: 0.5px solid black;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  background-color: whitesmoke;
}

.container_s img {
  transition: all 0.5s;
  height: auto;
  position: relative;
  left: 0;
  /* width: 300px; */
}

.container_s img:hover {
  box-shadow: 0px 4px 6px 4px lightblue;
  transform: scale(1.05);
}

/* font-weight: bold;
background-color: black;
padding: 4.2px;
width: 15vw; */

.container_s .name {
  position: relative;
  width: 16vw;
  padding: 4.2px;
  text-align: center;
  color: white;
  background-color: black;
  margin: 0 auto;
  bottom: 2vh;
  left: 0;
  /* color: #187bcd; */
  /* border: 4px solid #187bcd; */
    /* background-color: white; */
  /* border-radius: 0px 10px; */
}

.container_ms {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 0px;
  width: 95vw;
  border: 0.5px solid black;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  background-color: whitesmoke;
}

.container_ms img {
  transition: all 0.5s;
  height: 32vh;
  width: auto;
}

.container_ms img:hover {
  box-shadow: 0px 4px 6px 4px lightblue;
  transform: scale(1.05);
}

.container_ms .name {
  position: relative;
  width: 16vw;
  padding: 4.2px;
  text-align: center;
  color: white;
  background-color: black;
  margin: 0 auto;
  bottom: 2vh;
  left: 0;
}

.card__face {
  width: 23vw;
  display: flex;
  flex-direction: column;
}

.carousel {
  border: 0.5px solid black;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  /* padding-left: 45%; */
  background-image: url("/public/Assets/001.webp");
  justify-content: space-around;
}

.below_img {
  position: relative;
  display: flex;
  top: 0px;
  width: 15vw;
  height: 4vh;
  color: white;
  text-align: center;
  /* background-color: black; */
  justify-content: center;
  align-items: center;
  /* padding:5px; */
  margin: 0 auto;
}

.below_img p {
  font-weight: bold;
  background-color: black;
  padding: 4.2px;
  width: 15vw;
}

/* the space below each image in product carousel */
.sc-dnqmqq.bVoXXg {
  height: 35vh;
  width: 20.5vw;
}

.carousel img {
  transition: all 0.5s;
}

.carousel img:hover {
  transform: scale(1.1);
}

.row {
  display: flex;
  justify-content: space-evenly;
  perspective: 800px;
}

.column {
  position: relative;
  cursor: pointer;
  transition: 1s ease-in-out;
  transform-style: preserve-3d;
}

img {
  padding: 2vw;
  width: 22vw;
  height: 12vh;
  object-fit: cover;
}

.prodImg {
  padding: 0px;
  width: 20vw;
  height: 28vh;
  object-fit: cover;
  border: 2px solid #187bcd;
}

/* .name {
  position: relative;
  width: 18vw;
  text-align: center;
  color: #187bcd;
  border: 4px solid #187bcd;
  background-color: white;
  border-radius: 0px 10px;
  left: 15%;
  bottom: 5%;
} */

.carousel {
  padding: 0 0 0 4.6vw;
  width: 1200px;
  /* margin-left: 6%; */
}

span.sc-bwzfXH.kVtMtA {
  left: -70px;
}

@media only screen and (max-width: 768px) {
  .prodSection .carousel {
    width: 100%;
    margin: 0 auto;
    height: 22vh;
    padding-top: 1vh;
  }
  .prodImg {
    width: 50vw;
    height: 13vh;
    object-fit: cover;
    border: 2px solid #187bcd;
  }
  .below_img {
    font-size: 0.6rem;
    top: 1vh;
    width: 50vw;
  }
  .below_img p{
    width: 20vw;
  }
  .sc-EHOje.bghjHX {
    height: 20vh;
  }
  .container_s {
    width: 95vw;
    height: 18vh;
    flex-direction: row;
    justify-content: flex-start;
    overflow-x: scroll;
  }
  .container_s .name {
    width: 24vw;
    margin: 0 auto 1vh auto;
    bottom: -1vh;
    font-size: 0.5rem;
    font-weight: bold;
    left: 0;
  }
  .container_s img {
    transition: all 0.5s;
    height: auto;
    width: 32vw;
  }
  .card__face {
    width: 31vw;
  }
  .container_s img:hover {
    box-shadow: none;
    transform: scale(none);
  }

  .container_ms {
    justify-content: flex-start;
    padding: 0;
    width: 95vw;
    height: 20vh;
    overflow-x: scroll;
  }

  .container_ms img {
    transition: all 0.5s;
    height: 15vh;
    width: auto;
  }

  .container_ms img:hover {
    box-shadow: none;
    transform: scale(none);
  }
  .container_ms .name {
    width: 24vw;
    bottom: 0vh;
    font-size: 0.6rem;
    font-weight: bold;
    left: 0;
    margin-bottom: 1vh;
  }
  .prodSection .indication{
    display: flex;
    font-size: 0.7rem;
    position: relative;
    top: -3vh;
  }
  .sectorSection .indication{
    display: flex;
    font-size: 0.7rem;
    position: relative;
    top: -3vh;
  }    
  .manufacturingSection .indication{
    display: flex;
    font-size: 0.7rem;
    position: relative;
    top: -3vh;
  }
}
