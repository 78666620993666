.clientHeader {
  justify-content: center;
  display: flex;
}

/* Logo Slider */

.logo-slider {
  background: white;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 40vh;
}

.logo-slider::before,
.logo-slider::after {
  content: "";
  height: 175px;
  position: absolute;
  width: 200px;
  z-index: 2;
}

.logo-slider::after {
  right: 0;
  top: 0;
  -webkit-transform: rotateZ(180deg);
  transform: rotateZ(180deg);
}

.logo-slider::before {
  left: 0;
  top: 0;
}

.logo-slider .logo-slide-track {
  -webkit-animation: logo-scroll 60s linear infinite;
  animation: logo-scroll 60s linear infinite;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: calc(16vw * 16);
  animation-duration: 40s;
  animation-iteration-count: infinite;
  animation-delay: 1s;
}

.logo-slider .slide {
  height: 15vh;
  width: 15vw;
}

.logo-slider .slide img {
  height: auto;
  width: 18vw;
}

@keyframes logo-scroll {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(calc(-16vw * 10));
    transform: translateX(calc(-16vw * 10));
  }
}

@media only screen and (max-width: 768px) {
  .logo-slider {
    width: 95%;
    height: 25vh;
  }

  .logo-slider .slide {
    height: 15vh;
    width: 30vw;
  }

  .logo-slider .slide img {
    height: auto;
    width: 30vw;
  }
  @keyframes logo-scroll {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  
    100% {
      -webkit-transform: translateX(calc(-16vw * 26));
      transform: translateX(calc(-16vw * 26));
    }
  }
}
