.topnav {
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 40%,
        rgba(255, 255, 255, 0.6) 55%
    );
    overflow: hidden;
    padding: 15px 15px;
}

.topnav a {
    float: left;
    color: black;
    text-align: center;
    padding: 0.2vw 0.7vw;
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: bold;
    transition: all 0.5s;
}

.menu a:hover {
    color: #187bcd;
}

.topnav a:active {
    background-color: white;
    color: #187bcd;
}

.menu {
    display: flex;
    justify-content: flex-end;
    background-color: transparent;
    padding: 10px 10px;
}

.logo {
    position: absolute;
    left: -0.8%;
    top: -2.2vh;
    font-weight: bold;
    height: auto;
    width: 16.5vw;
    border-radius: 20% 0% 0% 0%;
    z-index: 2;
}

/* Added here dropdown */
/* The dropdown container */
.topnav .dropdown {
    overflow: hidden;
}

/* Dropdown button */
.topnav .dropdown .dropbtn {
    font-size: 16px;
    border: none;
    outline: none;
    color: white;
    background-color: inherit;
    font-family: inherit; /* Important for vertical align on mobile phones */
    margin: 0; /* Important for vertical align on mobile phones */
}

/* Add a red background color to navbar links on hover */
.navbar a:hover,
.topnav .dropdown:hover .dropbtn {
    background-color: #187bcd;
}

/* Dropdown content (hidden by default) */
.topnav .dropdown-content {
    display: none;
    position: absolute;
    /* background-color: rgb(188, 6, 6); */
    background-color: #187bcd;
    min-width: 12vw;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    top: 7.5vh;
}

/* Links inside the dropdown */
.topnav .dropdown-content a {
    float: none;
    color: white;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
    border-bottom: 1px solid white;
}

/* Add a 187bcd background color to dropdown links on hover */
.topnav .dropdown-content a:hover {
    background-color: white;
    color: #187bcd;
    font-weight: bold;
    transform: scale(1);
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: flex;
    flex-direction: column;
}

/* (C) HIDE HAMBURGER */
.topnav label,
#hamburger {
    display: none;
}

@media only screen and (max-width: 768px) {
    .logo {
        width: 35vw;
        left: 0%;
        top: 0vh;
    }
    .menu {
        display: none;
    }
    .menu a {
        box-sizing: border-box;
        display: block;
        width: 100%;
        text-align: center;
        color: white;
        background-color: #187bcd;
        border-top: 1px solid #333;
        padding: 0.7vh;
    }

    /* (B) SHOW HAMBURGER ICON */
    .topnav label {
        display: inline-block;
        color: white;
        background: #187bcd;
        font-style: normal;
        font-size: 1.2em;
        padding: 10px;
        float: right;
    }

    /* (C) TOGGLE SHOW/HIDE MENU */
    .menu {
        display: none;
    }
    .topnav input:checked ~ .menu {
        display: flex;
        flex-direction: column;
    }
    .topnav .dropdown-content {
        position: static;
    }
}